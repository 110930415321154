import * as THREE from "three";
import {
	forwardRef,
	useLayoutEffect,
	useRef,
	useMemo,
	useState,
	useEffect,
	useCallback,
} from "react";
import { extend, useLoader, useFrame, useThree } from "@react-three/fiber";
import lerp from "lerp";
import "./CustomMaterial";

const Image = ({
	isMobile,
	primaryImage,
	secondaryImage,
	thirdImage,
	fourthImage,
	dispImage,
}) => {
	const [texture, secondaryTexture, thirdTexture, fourthTexture, disp] =
		useLoader(THREE.TextureLoader, [
			primaryImage?.src,
			secondaryImage,
			thirdImage,
			fourthImage,
			dispImage,
		]);

	const refMesh = useRef();
	const refMaterial = useRef();
	const [transformed, setTransformed] = useState(false);
	const { viewport } = useThree();

	const onTransform = () => {
		setTransformed(!transformed);
	};

	const repeatPulseTransform = (count) => {
		// console.log("repeatpulse", refMaterial.current.index);
		setTransformed(count % 2 == 0);

		if (refMaterial.current.index < 3) {
			// if (refMaterial.current.index < 3) {
			refMaterial.current.index++;
		} else {
			refMaterial.current.index = 0;
		}

		setTimeout(() => repeatPulseTransform(count + 1), 4000);
	};

	useEffect(() => {
		repeatPulseTransform(0);
	}, []);

	useFrame(
		() =>
			(refMaterial.current.dispFactor = lerp(
				refMaterial.current.dispFactor,
				transformed ? 1 : 0,
				0.008
			))
	);

	useMemo(() => {
		texture.generateMipmaps = false;
		texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
		texture.minFilter = THREE.LinearFilter;
		texture.needsUpdate = true;
	}, [texture]);

	const { width, height, top, left } = primaryImage.getBoundingClientRect();
	const [scaleX, setScaleX] = useState();
	const [scaleY, setScaleY] = useState();
	const [posX, setPosX] = useState();

	const getPosY = useCallback(() => {
		return (
			-((height / window.innerHeight) * viewport.height) / 2 +
			viewport.height / 2 -
			(top / window.innerHeight) * viewport.height
		);
	}, [height, top, viewport.height]);

	const [posY] = useState(() => getPosY());

	useEffect(() => {
		setScaleX((width / window.innerWidth) * viewport.width);
		setScaleY((height / window.innerHeight) * viewport.height);
		setPosX(
			((width / window.innerWidth) * viewport.width) / 2 -
				viewport.width / 2 +
				(left / window.innerWidth) * viewport.width
		);
	}, [width, height, top, left, viewport.width, viewport.height]);

	return (
		<mesh
			ref={refMesh}
			// onPointerDown={onTransform}
			scale={isMobile ? [0.66, 0.66, 0.66] : [1, 1, 1]}
			// scale={[scaleX, scaleY, 1]}
			// scale={[scaleX, scaleY, 1]}
			// position={[posX, posY, 0]}
		>
			{/* <planeBufferGeometry attach="geometry" /> */}
			{/* <boxGeometry attach="geometry" args={[250, 250, 250]} /> */}
			{/* <sphereGeometry attach="geometry" args={[250, 500, 250]} /> */}
			<boxGeometry
				attach="geometry"
				args={[500, 500, 10]}
			/>
			{/* <meshStandardMaterial color={transformed ? "hotpink" : "orange"} /> */}
			<customMaterial
				ref={refMaterial}
				attach="material"
				tex={texture}
				tex2={secondaryTexture}
				tex3={thirdTexture}
				tex4={fourthTexture}
				disp={disp}
			/>
		</mesh>
	);
};

export default Image;
