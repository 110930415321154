import * as THREE from "three";
import React, {
  Suspense,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { OrbitControls, Stars, Html } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";

import Image from "./components/Image";
import { Block } from "./components/blocks";

import state from "./store";

import primaryImage from "./images/sisun.jpeg";
import secondaryImage from "./images/danny.jpeg";
import thirdImage from "./images/alex1.jpg";
import fourthImage from "./images/alex2.jpg";
import dispImage from "./images/disp2.jpeg";

function App() {
  const [hovered, hover] = useState(false);
  const mouse = useRef([0, 0]);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const scrollArea = useRef();
  const onScroll = (e) => (state.top.current = e.target.scrollTop);
  useEffect(() => void onScroll({ target: scrollArea.current }));

  const refDomContent = useRef();
  const [refImageCurrent, setRefImageCurrent] = useState();

  const refImage = useCallback((node) => {
    if (node !== null) {
      setRefImageCurrent(node);
    }
  }, []);

  return (
    <>
      <Canvas
        className="canvas"
        orthographic
        camera={{ position: [0, 75, 500] }}
      >
        <Suspense
          fallback={<Html center className="loading" children="Waking up..." />}
        >
          <OrbitControls autoRotate autoRotateSpeed={3.2} />
          <Stars
            radius={100}
            depth={100}
            count={10000}
            factor={5}
            saturation={0}
            fade
          />
          <ambientLight />
          <Block factor={1.5} offset={0}>
            {refImageCurrent && (
              <Image
                isMobile={isMobile}
                primaryImage={refImageCurrent}
                secondaryImage={secondaryImage}
                thirdImage={thirdImage}
                fourthImage={fourthImage}
                dispImage={dispImage}
              />
            )}
            <Html fullscreen portal={refDomContent}>
              <img ref={refImage} className="img--primary" src={primaryImage} />
            </Html>
          </Block>
        </Suspense>
      </Canvas>
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
        <div style={{ position: "sticky", top: 0 }} ref={refDomContent} />
        <div style={{ height: `${state.pages * 100}vh` }} />
      </div>
    </>
  );
}

export default App;
